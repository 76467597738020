<template>
<div class="card p-3">
  <dl class="m-0" v-if="this.unidadadministrativa_id === '1'">
    <dt>ALIAS:</dt>
    <dd>
      <b>PAGO.SAED</b>
      <button type="button" @click="copyToClipboard('PAGO.SAED', 'Alias')" class="copy-btn">Copiar</button>
    </dd>
    <dt>CBU:</dt>
    <dd>
      <b>0440034930000009620686</b>
      <button type="button" @click="copyToClipboard('0440034930000009620686', 'CBU')" class="copy-btn">Copiar</button>
    </dd>
    <dt>Banco:</dt>
    <dd><b>BANCO HIPOTECARIO S.A.</b></dd>
    <dt>CUIT:</dt>
    <dd><b>30-67508895-7</b></dd>
    <dt>Destinatario:</dt>
    <dd class="m-0"><b>Saed Sede Admin Escuelas Diocesanas Obispado de Santiago del Estero</b></dd>
  </dl>
  <dl class="m-0" v-if="this.unidadadministrativa_id === '3'">
    <dt>CBU:</dt>
    <dd>
      <b>0170071820000032312900</b>
      <button type="button" @click="copyToClipboard('0170071820000032312900', 'CBU')" class="copy-btn">Copiar</button>
    </dd>
    <dt>Banco:</dt>
    <dd><b>BBVA Banco Francés</b></dd>
    <dt>CUIT:</dt>
    <dd><b>30-67508895-7</b></dd>
    <dt>Destinatario:</dt>
    <dd class="m-0"><b>PARROQUIA NUESTRA SE</b></dd>
    <!-- <dt>Alias:</dt>
    <dd>alias.saed</dd> -->
  </dl>
  <div v-if="showCopyMessage" class="copy-message">
    {{ copyType }} copiado al portapapeles
  </div>
  </div>
</template>

<script>
export default {
  name: "CardTransferenciaCuenta",
  components: {
  },
  data() {
    return {
      unidadadministrativa_id: process.env.VUE_APP_UA,
      showCopyMessage: false,
      copyType: 'Texto'
    };
  },
  methods: {
    copyToClipboard(text, type) {
      navigator.clipboard.writeText(text)
        .then(() => {
          this.copyType = type;
          this.showCopyMessage = true;
          setTimeout(() => {
            this.showCopyMessage = false;
          }, 2000);
        })
        .catch(err => {
          console.error('Error al copiar texto: ', err);
        });
    }
  }
}
</script>

<style scoped>
dt {
  float: left;
  clear: left;
  margin-right: 5px;
  font-weight: bold;
}

dd {
  margin-left: 0px;
}

.copy-btn {
  margin-left: 10px;
  padding: 2px 6px;
  font-size: 0.8rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #f8f9fa;
  cursor: pointer;
}

.copy-btn:hover {
  background-color: #e9ecef;
}

.copy-message {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 15px;
  border-radius: 4px;
  z-index: 1000;
}
</style>

<template>
  <div>

    <h5 class="my-4">Elegir forma de pago</h5>

    <b-card bg-variant="light" header="Gestión presencial" header-tag="header">
        <b-button-group class="flex-wrap">
          <button v-if="this.enable_cajamunicipal && this.nivelenseñanza.CodigoImpuestoCM !== 'NULL' && this.unidadadministrativa_id === '1'" @click="showfp(0)">
              <img src="img/cm38.png" />
          </button>
          <button v-if="this.nivelenseñanza.CodigoImpuestoCM !== 'NULL' && this.unidadadministrativa_id === '1'" @click="showfp(7)">
              <img src="img/bse30.png" />
          </button>
          <button v-if="this.unidadadministrativa_id === '3'" @click="showfp(8)">
              <img src="img/siro30.png" />
          </button>
          <button v-if="this.unidadadministrativa_id === '1'" @click="showfp(6)">
              <img src="img/pf32.png" />Pago Fácil
          </button>        
          <button @click="showfp(2)">
              <img src="img/dep84.png" />
          </button>
        </b-button-group>
    </b-card>

    <br>

    <b-card bg-variant="light" header="Gestión online" header-tag="header">
        <b-button-group class="flex-wrap">
          <button @click="showfp(1)">
              <img src="img/tr38.png" />
          </button>
          <button v-if="this.unidadadministrativa_id === '1'" @click="showfp(5)">
              <img src="img/lnk40.png" />Link pagos
          </button>
          <!-- <button @click="showfp(3)">
              <img src="img/mp80.png" />
          </button> -->
        </b-button-group>
    </b-card>

    <br>

    <b-card v-if="this.unidadadministrativa_id === '1'" bg-variant="light" header="Gestión automática" header-tag="header">
        <b-button-group class="flex-wrap">
          <button @click="showfp(4)">
              <img src="img/dd29.png" />Debito Directo
          </button>
        </b-button-group>
    </b-card>
  </div>
</template>
<script>
import { createHelpers } from "vuex-map-fields";
const { mapFields: mapFieldsAlumno } = createHelpers({
  getterType: "AlumnoModule/getAlumnoField",
  mutationType: "AlumnoModule/updateAlumnoField",
});
const { mapFields: mapFieldsPago } = createHelpers({
  getterType: "PagoModule/getPagoField",
  mutationType: "PagoModule/updatePagoField",
});
export default {
  name: "CardFormaPago",
  props: {
    titulo: String
  },
  watch: {

  },
  computed: {
    ...mapFieldsAlumno(['alumno']),
    ...mapFieldsPago(['formapago', 'nivelenseñanza']),
    alumnoIsValid() {
        return (
            this.alumno !== null &&
            typeof this.alumno !== "undefined" &&
            this.alumno !== false
        );
    }
  },
  components: {
  },
  data() {
    return {
      unidadadministrativa_id: process.env.VUE_APP_UA,
      enable_cajamunicipal: process.env.VUE_APP_ENABLE_CAJAMUNICIPAL == 'true'
    };
  },
  methods: {
    showfp: function(fp){
      console.log(fp);
      this.formapago = fp;
      this.$router.push('registrarpago');
      // if(fp === 0){
        
      // }
    }
  },
  mounted() {
  }
}
</script>